require("./bootstrap");

import React from "react";
import ReactDOM from "react-dom";
import Events from "./events";

const App = () => {
    return <Events />;
};

if (document.getElementById("calendar")) {
    ReactDOM.render(<App />, document.getElementById("calendar"));
}
